<template>
  <div>
    <div id="contasReceber" class="cardTable mb-1 vs-con-loading__container">
      <div class="mt-1" v-if="contas.length == 0 || contas.length == undefined">
        <div class="pt-20 scroll-contas-receber con-colors">
          <ul class="pt-20">
            <li class="danger-box">
              <h2 class="p-5" style="color: white !important">
                Nenhuma Conta a Receber
              </h2>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="scroll-contas-receber">
        <b-table-simple sticky-header="80vh" striped responsive>
          <b-thead>
            <b-tr>
              <b-th style="width: 5%" class="text-center">Nota</b-th>
              <b-th style="width: 5%" class="text-center">Emissão</b-th>
              <b-th style="width: 5%" class="text-center">Vencimento</b-th>
              <b-th style="width: 5%" class="text-center">Quitação</b-th>
              <b-th style="width: 10%" class="text-center">Cliente</b-th>
              <b-th style="width: 10%" class="text-center">Empresa</b-th>
              <b-th style="width: 2%" class="text-center">Contrato</b-th>
              <b-th style="width: 8%" class="text-center">Valor</b-th>
              <b-th style="width: 8%" class="text-center">Juros</b-th>
              <b-th style="width: 8%" class="text-center">V. Pago</b-th>
              <b-th style="width: 12%" class="text-center">Plano Conta</b-th>
              <b-th style="width: 10%" class="text-center">Ações</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              :style="
                conta.negativado > 0
                  ? 'color:red ;background-color: #e1bee7 !important'
                  : conta.vencido > 0
                  ? 'color:red ;background-color: #fce3e3 !important'
                  : conta.id_status == 4
                  ? 'color: green;background-color:#e8fce8 !important;'
                  : conta.id_status == 3
                  ? 'color:#00BBA2;background-color: #dff2f0 !important'
                  : ''
              "
              :key="index"
              v-for="(conta, index) in contas"
            >
              <b-td style="font-size: 12px" class="text-center font-bold">
                {{
                  conta.nota > 0
                    ? conta.nota + (conta.parcela ? "." + conta.parcela : "")
                    : "..."
                }}
              </b-td>
              <b-td style="font-size: 12px" class="text-center">
                {{ conta.emissao ? conta.emissao : "..." }}
              </b-td>
              <b-td style="font-size: 12px" class="text-center">
                {{ conta.vencimento ? conta.vencimento : "..." }}
              </b-td>
              <b-td style="font-size: 12px" class="text-center">
                {{ conta.pagamento ? conta.pagamento : "..." }}
              </b-td>
              <b-td>
                <vs-button
                  color="dark"
                  class="py-0 px-0 my-0 pr-1"
                  size="small"
                  type="line"
                  @click="
                    renderComponent('VisualizarCliente', conta.id_cliente)
                  "
                >
                  <span class="nome-cliente-small">
                    {{ conta.cliente ? conta.cliente : "..." }}
                  </span>
                </vs-button>
              </b-td>
              <b-td style="font-size: 12px" class="text-center">
                {{ conta.empresa ? conta.empresa : "..." }}
              </b-td>
              <b-td class="text-center">
                <a
                  target="_blank"
                  :href="'contrato?id=' + conta.id_contrato"
                  style="color: darkblue; cursor: pointer; font-size: 10px"
                >
                  <b>{{ conta.id_contrato }}</b>
                </a>
              </b-td>
              <b-td style="font-size: 12px" class="text-center font-bold">
                {{ $currency(conta.valor) }}
              </b-td>
              <b-td style="font-size: 12px" class="text-center font-bold">
                {{ $currency(conta.juros) }}
              </b-td>
              <b-td style="font-size: 12px" class="text-center">
                <div
                  @click="
                    conta.valor_pago > 0
                      ? renderComponent('pagamentoParcial', conta)
                      : 1
                  "
                  style="
                    font-size: 12px;
                    cursor: pointer;
                    text-decoration: underline;
                  "
                >
                  <b>
                    {{
                      $currency(
                        parseFloat(conta.valor_pago) +
                          parseFloat(conta.juros || 0)
                      )
                    }}
                  </b>
                </div>
              </b-td>
              <b-td style="font-size: 12px" class="text-center">
                {{ conta.plano_contas ? conta.plano_contas : "..." }}
              </b-td>
              <b-td style="font-size: 12px">
                <div class="w-full">
                  <vs-row
                    vs-w="12"
                    vs-type="flex"
                    vs-justify="center"
                    vs-align="center"
                  >
                    <vs-col
                      :vs-w="conta.vencido || conta.negativado ? 3 : 4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-button
                        icon="edit"
                        @click="renderComponent('Editar', conta)"
                        size="small"
                        :disabled="
                          !$atividades.permissoes.includes(5) ||
                            conta.id_status == 4 ||
                            conta.id_status == 5
                        "
                        color="primary"
                      />
                    </vs-col>
                    <vs-col
                      :vs-w="conta.vencido || conta.negativado ? 3 : 4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-button
                        icon="done"
                        :disabled="
                          !$atividades.permissoes.includes(5) ||
                            conta.id_status == 4 ||
                            conta.id_status == 5
                        "
                        @click="renderComponent('Quitar', conta)"
                        size="small"
                        :color="conta.id_status == 4 ? '#128712' : 'success'"
                      />
                    </vs-col>
                    <vs-col
                      :vs-w="conta.vencido || conta.negativado ? 3 : 4"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <el-popconfirm
                        :confirmButtonText="
                          conta.id_status > 2 ? 'Desquitar' : 'Excluir conta'
                        "
                        cancelButtonText="Cancelar"
                        :icon="
                          conta.id_status > 2
                            ? 'el-icon-circle-close'
                            : 'el-icon-delete'
                        "
                        @confirm="
                          conta.id_status > 2
                            ? quitacaoDelet(conta)
                            : deletar(conta)
                        "
                        iconColor="red"
                        :title="
                          conta.id_status > 2
                            ? conta.id_status == 3
                              ? 'Cancelar quitação parcial desta conta?'
                              : 'Cancelar quitação desta conta?'
                            : 'Deseja excluir esta conta?'
                        "
                      >
                        <vs-button
                          :disabled="
                            !$atividades.permissoes.includes(6) ||
                              buttonDisabled ||
                              conta.id_status > 4
                          "
                          slot="reference"
                          icon="close"
                          size="small"
                          color="danger"
                        />
                      </el-popconfirm>
                    </vs-col>
                    <vs-col
                      v-if="conta.vencido && !conta.negativado"
                      vs-w="3"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vs-button
                        icon="info"
                        :disabled="!$atividades.permissoes.includes(5)"
                        @click="renderComponent('Negativar', conta)"
                        size="small"
                        color="#311b92"
                      />
                    </vs-col>
                    <vs-col
                      v-else-if="conta.negativado"
                      vs-w="3"
                      vs-type="flex"
                      vs-justify="center"
                      vs-align="center"
                    >
                      <vx-tooltip color="#311b92" :text="conta.motivo">
                        <vs-button
                          icon="reply"
                          :disabled="!$atividades.permissoes.includes(5)"
                          @click="confirmarDesnegativacao(conta)"
                          size="small"
                          color="#311b92"
                        />
                      </vx-tooltip>
                    </vs-col>
                  </vs-row>
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </div>
    <vx-card no-shadow card-background="dark">
      <vs-row
        vs-type="flex"
        class
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1"
          class
        >
          <vs-button
            @click="voltar()"
            icon-pack="feather"
            line-origin="left"
            color="#fff"
            type="line"
            icon="icon-arrow-left"
            size="small"
          >
            <span style="font-size: 14px">Voltar</span>
          </vs-button>
        </vs-col>
        <vs-col
          vs-w="7"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
        >
          <span style="color: white; font-size: 14px" class="ml-10 pt-3"
            >Contas: {{ contas.length }}</span
          >
          <span style="color: white; font-size: 12px" class="ml-10 pt-3"
            >Valor Total: {{ $currency(total) }}</span
          >
          <span style="color: white; font-size: 12px" class="ml-10 pt-3"
            >Juros Total: {{ $currency(totalJuros) }}</span
          >
          <span style="color: white; font-size: 12px" class="ml-10 pt-3"
            >Valor Recebido: {{ $currency(totalRecebido) }}</span
          >
          <span style="color: white; font-size: 12px" class="ml-10 pt-3"
            >Recebido + Juros: {{ $currency(totalRecebidoJuros) }}</span
          >
        </vs-col>
        <vs-col
          vs-w="4"
          vs-type="flex"
          vs-justify="flex-end"
          vs-align="flex-end"
        >
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <Cadastrar @update="getContasReceber" />
          </vs-col>
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <Filtro @filtro="Filtrar" />
          </vs-col>
          <vs-col vs-w="2.7" vs-justify="flex-end" vs-align="flex-end">
            <vs-button
              icon="get_app"
              type="line"
              @click="exportToExcel()"
              color="#FFCA00"
              size="small"
            >
              <b style="font-size: 16px">Excel</b>
            </vs-button>
          </vs-col>
        </vs-col>
      </vs-row>
    </vx-card>

    <component
      v-bind:is="buttonComponent"
      :conta="selectedItem"
      :id_cliente="selectedItem"
      :key="reRenderButtonComponent"
      @update="getContasReceber"
    />
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../../../cliente/visualizarSemBotao.vue";
import Filtro from "./components/filtro";
import Cadastrar from "./components/cadastrar";
import Quitar from "./components/quitar";
import pagamentoParcial from "./components/pagamentoParcial";
import Editar from "./components/editar";
import Negativar from "./components/negativar";
export default {
  data() {
    return {
      settings: {
        maxScrollbarLength: 160,
        wheelSpeed: 9.5,
        height: 1000
      },
      filtro: {
        dataHoje: this.$formartData.dataFiltro()
      },
      contas: {},
      totalRecebido: 0,
      totalRecebidoJuros: 0,
      total: 0,
      totalJuros: 0,
      buttonDisabled: false,
      buttonComponent: "",
      reRenderButtonComponent: 0,
      selectedItem: {}
      // contasShow: [],
      // contasShowCount: 1,
    };
  },
  methods: {
    async getContasReceber() {
      await this.$vs.loading({ container: "#contasReceber", scale: 0.6 });
      try {
        this.contas = await this.$http.post(`getContasReceber`, this.filtro);
        // this.contasShow = this.contas.slice(0, 10)
        // this.contasShowCount = 5
        this.total = await this.contas.reduce(function(prev, cur) {
          return prev + cur.valor;
        }, 0);
        this.totalRecebido = await this.contas.reduce(function(prev, cur) {
          return prev + cur.valor_pago;
        }, 0);
        this.totalJuros = await this.contas.reduce(function(prev, cur) {
          return prev + cur.juros;
        }, 0);
        this.totalRecebidoJuros =
          (await this.contas.reduce(function(prev, cur) {
            return prev + cur.valor_pago;
          }, 0)) + this.totalJuros;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#contasReceber > .con-vs-loading");
    },
    async exportToExcel() {
      try {
        import("@/vendor/Export2Excel").then(async excel => {
          let headerTitle = [
            "Nota",
            "Parcela",
            "Data Emissão",
            "Data Vencimento",
            "Data Pagamento",
            "Contrato",
            "Valor",
            "Juros",
            "Valor Pago",
            "Plano Conta",
            "Cliente",
            "CPF/CNPJ",
            "Telefone Principal",
            "Telefone Geral",
            "Email Principal",
            "Email Geral",
            "Enderecos"
          ];
          let headerVal = [
            "nota",
            "parcela",
            "emissao",
            "vencimento",
            "pagamento",
            "id_contrato",
            "valor",
            "juros",
            "valor_pago",
            "plano_contas",
            "cliente",
            "cpf_cnpj",
            "telefone_principal",
            "telefone_geral",
            "email_principal",
            "email_geral",
            "enderecos"
          ];
          const list = this.contas;
          const data = await this.formatJson(headerVal, list);
          excel.export_json_to_excel({
            header: headerTitle,
            data,
            filename: "Contas a Receber",
            autoWidth: false,
            bookType: "xlsx"
          });
        });
        this.$vs.loading.close();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    async voltar() {
      this.$router.push("/financeiro");
    },
    async Filtrar(filtro) {
      this.filtro = await filtro;
      await this.getContasReceber();
    },
    async deletar(conta) {
      this.buttonDisabled = await true;
      try {
        await this.$http.put(`conta/` + conta.id, { id_status: 5 });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "excluir",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Exclusão da Conta a Receber N°" + conta.id
        };
        await this.$logger(logData);
        await this.getContasReceber();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.buttonDisabled = await false;
      }
    },
    async quitacaoDelet(conta) {
      if(this.$atividades.permissoes.includes(17)){
        this.buttonDisabled = await true;
        try {
          await this.$http.put(`conta/` + conta.id, {
            id_status: 1,
            data_pagamento: null
          });
          await this.$http.delete(`desquitar/` + conta.id);
          let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: "excluir",
            setor: "financeiro",
            ip: window.localStorage.getItem("ip"),
            texto: "Exclusão da Quitação da Conta a Pagar N°" + conta.id
          };
          await this.$logger(logData);
          await this.getContasReceber();
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.buttonDisabled = await false;
        }
      }else{
        this.$vs.notify({title: "Não autorizado", text: "Você não tem autorização para realizar esta ação", color: "danger"})
      }
      
    },
    async contrato(id_contrato) {
      this.$router.push("/contrato?id=" + id_contrato);
    },
    async renderComponent(component2Render, item) {
      this.buttonComponent = component2Render;
      this.selectedItem = item;
      this.reRenderButtonComponent++;
    },
    async confirmarDesnegativacao(item) {
      this.selectedItem = item;
      this.modalEnviarVia = await false;
      await this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: `Deseja desnegativar esta conta?`,
        acceptText: "Confirmar",
        cancelText: "Cancelar",
        text: `Motivo: ${item.motivo}`,
        accept: await this.desnegativar
      });
    },
    async desnegativar() {
      this.buttonDisabled = await true;
      try {
        await this.$http.put("conta/" + this.selectedItem.id, {
          negativado: 0,
          motivo: ""
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "update",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Desnegativação da conta N° " + this.selectedItem.id
        };
        await this.$logger(logData);
        await this.getContasReceber();
      } catch (err) {
        const error = this.$httpErrors(err);
        await this.$vs.notify(error);
      } finally {
        this.buttonDisabled = await false;
      }
    }
  },
  async mounted() {
    if (this.$route.query.cliente > 0) {
      this.filtro.id_cliente = this.$route.query.cliente;
    }
    if (this.$route.query.contrato > 0) {
      this.filtro.id_contrato = this.$route.query.contrato;
    }
    await this.getContasReceber();
  },
  components: {
    Filtro,
    ...components,
    Cadastrar,
    Editar,
    Quitar,
    VisualizarCliente,
    pagamentoParcial,
    Negativar
  }
};
</script>
<style lang="scss">
.scroll-contas-receber {
  height: 82vh !important;
}
.cardTable {
  transition-duration: 300ms;
  border-style: solid;
  border-radius: 4px;
  border-color: rgba(211, 211, 211, 0.493);
  border-width: 2px;
  &:hover {
    transition-duration: 555ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(226, 226, 226, 0.25);
  }
}
thead tr th#center {
  .vs-table-text {
    justify-content: center !important;
  }
}
.el-popconfirm__main {
  font-size: 12px !important;
}
.table_height {
  height: 72vh !important;
}
.nome-cliente-small {
  font-weight: 600;
  font-size: 10px;
}
</style>
