<template>
  <div class="w-full">
    <vs-popup v-if="open" title="Negativar Conta" :active.sync="modalNegativar">
      <div class="w-full">
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <div class="w-full">
              <vs-textarea
                color="dark"
                class="mx-1 w-full"
                type="text"
                name="motivo"
                height="100"
                v-validate="'required'"
                label="Motivo"
                v-model="negativar.motivo"
              ></vs-textarea>
            </div>
            <span class="text-danger text-sm" v-show="errors.has('motivo')">{{
              $validators.empty
            }}</span>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <div
              :id="'loadingModalNegativar' + conta.id"
              class="w-full vs-con-loading__container"
            >
              <vs-button
                @click="submitForm()"
                class="w-full my-3"
                color="#311b92"
                type="relief"
                >Negativar</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"
export default {
  props: {
    conta: null,
  },
  data () {
    return {
      open: false,
      modalNegativar: false,
      negativar: { negativado: 1 },
    }
  },
  methods: {
    async submitForm () {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.negativarConta()
          } else {
            this.$vs.notify({
              title: "Preencha todos os campos",
              text: "Os campos não devem ser vazios!",
              color: "danger"
            })
          }
        })
      })
    },
    async openModal () {
      this.open = true
      this.modalNegativar = true
    },
    async negativarConta () {
      await this.$vs.loading({
        container: "#loadingModalNegativar" + this.conta.id,
        scale: 0.6
      })
      try {
        await this.$http.put("conta/" + this.conta.id, this.negativar)
      } catch (err) {
        const error = this.$httpErrors(err)
        await this.$vs.notify(error)
      } finally {
        await this.$emit("update")
        this.modalNegativar = await false
        this.open = await false
        await this.$vs.loading.close(
          "#loadingModalNegativar" + this.conta.id + " > .con-vs-loading"
        )
      }
    },
  },
  async mounted () {
    await this.openModal()
  },
  components: { ...components }
};
</script>
