<template>
  <div class="w-full">
    <vs-popup
      v-if="open"
      title="Quitar Conta a Receber"
      :active.sync="modalQuitar"
    >
      <div
        class="w-full vs-con-loading__container"
        :id="'loadingQuitarCR' + this.conta.id"
      >
        <vs-row
          vs-type="flex"
          class="mb-5"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-input
              color="dark"
              style="color: black; width: 340px"
              class="mx-1"
              :disabled="true"
              name="valor"
              min="0"
              label="Cliente"
              v-model="conta.cliente"
            ></vs-input>
            <vs-input
              color="dark"
              class="mx-1"
              :disabled="true"
              name="valor"
              min="0"
              style="color: black; width: 220px"
              label="Número da Conta"
              v-model="movimento.id_conta"
            ></vs-input>
          </vs-col>
        </vs-row>
        <div
          class="w-full vs-con-loading__container"
          :id="'loadingSelectsQuitCR' + this.conta.id"
        >
          <vs-row
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
              vs-w="12"
            >
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Portador</label
                >
                <el-select
                  filterable
                  name="portador"
                  clearable
                  style="width: 181px"
                  :popper-append-to-body="false"
                  v-model="movimento.id_portador"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="portador in portadores"
                    :value="portador.id"
                    :label="portador.nome"
                    :key="portador.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Plano Conta</label
                >
                <el-select
                  filterable
                  clearable
                  style="width: 181px"
                  remote
                  :loading="loading"
                  :remote-method="filtroPlanoConta"
                  name="plano_conta"
                  :popper-append-to-body="false"
                  placeholder="Pesquise"
                  v-model="movimento.id_plano_conta"
                  class="mx-1 mt-2"
                >
                  <el-option
                    v-for="data in plano_conta"
                    :value="data.id"
                    :label="data.nome"
                    :key="data.id"
                  ></el-option>
                </el-select>
              </div>
              <div>
                <label style="color: black; font-size: 0.85rem" class="m-0 pl-3"
                  >Forma de Pagamento</label
                >
                <el-select
                  filterable
                  clearable
                  name="forma"
                  style="width: 181px"
                  v-model="movimento.id_forma_pagamento"
                  class="mx-1 mt-2"
                  :popper-append-to-body="false"
                >
                  <el-option
                    v-for="formaPagamento in formas_pagamento"
                    :value="formaPagamento.id"
                    :label="formaPagamento.nome"
                    :key="formaPagamento.id"
                  ></el-option>
                </el-select>
              </div>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          class="mt-4"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                type="text"
                icon-after="true"
                icon="R$"
                name="valor"
                style="width: 181px"
                ref="movimentoValor"
                v-currency="$currencyFormatterOptions"
                label="Valor do Pagamento"
                v-model="movimento.valor"
              ></vs-input>
            </div>
            <div>
              <vs-input
                color="dark"
                class="mx-1"
                name="valor"
                style="width: 181px"
                icon-after="true"
                icon="R$"
                type="text"
                label="Juros"
                v-model="movimento.juros"
              ></vs-input>
            </div>
            <div>
              <vs-input
                color="dark"
                name="emissao"
                class="mx-2"
                style="width: 181px"
                @change="checkData"
                type="date"
                label="Data da Quitação"
                v-model="movimento.data"
              ></vs-input>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1 w-full mt-4"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="movimento.obs"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-start"
            vs-w="12"
          >
            <vs-button
              @click="submitForm()"
              class="w-full mt-3"
              color="success"
              type="relief"
              >Quitar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  props: {
    conta: {}
  },
  data() {
    return {
      open: false,
      modalQuitar: false,
      movimento: { valor: 0 },
      portadores: {},
      plano_conta: {},
      formas_pagamento: {},
      loading: false,
      data_hoje: null
    };
  },
  methods: {
    async openModal() {
      this.open = await true;
      this.modalQuitar = await true;
      await this.$vs.loading({
        container: "#loadingQuitarCR" + this.conta.id,
        scale: 0.6
      });
      try {
        await this.getPortadores();
        await this.getPlanoContaByQuery(this.conta.id_plano_conta);
        await this.getFormasPagamento();
        await this.setObject();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#loadingQuitarCR" + this.conta.id + "> .con-vs-loading"
        );
      }
    },
    async submitForm() {
      try {
        if (
          this.movimento.id_portador != null &&
          this.movimento.id_plano_conta != null &&
          this.movimento.id_forma_pagamento != null &&
          this.movimento.valor != null &&
          this.movimento.data != null && this.movimento.data != "" && this.movimento.data != undefined
        ) {
          await this.Quitar();
        } else {
          this.$vs.notify({
            title: "Preencha os campos obrigatorios",
            text: "Portador <br> Plano Conta <br> Valor <br> Data",
            color: "danger"
          });
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async Quitar() {
      await this.$vs.loading({
        container: "#loadingQuitarCR" + this.conta.id,
        scale: 0.6
      });
      try {
        let valor = "" + this.movimento.valor;
        if (valor && valor.search(",") !== -1) {
          valor = valor.replace(/\./g, "");
          valor = valor.replace(/,/g, ".");
        }
        this.movimento.valor = valor;
        let juros = "" + this.movimento.juros;
        if (juros && juros.search(",") !== -1) {
          juros = juros.replace(/\./g, "");
          juros = juros.replace(/,/g, ".");
        }
        this.movimento.juros = juros;

        await this.$http.post("quitarConta/" + this.conta.id, [
          this.movimento,
          this.conta
        ]);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto: "Quitação da Conta a Receber N°" + this.conta.id
        };
        await this.$logger(logData);
        this.modalQuitar = await false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#loadingQuitarCR" + this.conta.id + " > .con-vs-loading"
        );
        await this.$emit("update");
      }
    },
    async setObject() {
      let hoje = await this.$formartData.dataFiltro();
      this.data_hoje = hoje;
      let valor = (
        parseFloat(this.conta.valor) - (parseFloat(this.conta.valor_pago) || 0)
      ).toFixed(2);
      valor = valor.replace(/\./g, ",");
      this.movimento = {
        id_conta: this.conta.id,
        id_cliente: this.conta.id_cliente,
        id_empresa: this.conta.id_empresa,
        id_portador: this.conta.id_portador,
        id_fornecedor: this.conta.id_fornecedor,
        id_forma_pagamento: this.conta.id_forma_pagamento,
        id_plano_conta: 102,
        obs: this.conta.obs,
        tipo_movimento: 2,
        valor: parseFloat(valor) > 0 ? valor : 0,
        juros: 0,
        data: hoje
      };
    },
    async getPortadores() {
      try {
        this.portadores = await this.$http.get(`portador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPlanoContaByQuery(query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroPlanoConta(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getPlanoContaByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    },
    async checkData() {
      if (this.$atividades.permissoes.includes(17)) {
        return 1;
      }
      var hoje = new Date();
      var data = new Date(this.movimento.data);

      var dia_hoje = hoje.getUTCDate();
      var mes_hoje = hoje.getUTCMonth() + 1;
      var dia_data = data.getUTCDate();
      var mes_data = data.getUTCMonth() + 1;
      var ano_hoje = hoje.getUTCFullYear();
      var ano_data = data.getUTCFullYear();

      console.log("dia_hoje - " + dia_hoje);
      console.log("mes_hoje - " + mes_hoje);
      console.log("dia_data - " + dia_data);
      console.log("mes_data - " + mes_data);
      console.log("ano_hoje - " + ano_hoje);
      console.log("ano_data - " + ano_data);
      if (ano_data < ano_hoje && 0) {
        this.movimento.data = hoje.toISOString().split("T")[0];
        this.$vs.notify({
          title: "Não é possível movimentar o ano anterior. ERR COD 03",
          text: "Ação Negada",
          color: "danger"
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "burl",
          setor: "financeiro",
          ip: window.localStorage.getItem("ip"),
          texto:
            "Tentou quitar para uma data anterior da conta receber N°" +
            this.conta.id
        };
        await this.$logger(logData);
        return 1;
      }
      if (dia_hoje > 5) {
        if (mes_data < mes_hoje) {
          this.movimento.data = hoje.toISOString().split("T")[0];
          this.$vs.notify({
            title: "Não é possível movimentar o mês anterior. ERR COD 01",
            text: "Ação Negada",
            color: "danger"
          });
          let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: "burl",
            setor: "financeiro",
            ip: window.localStorage.getItem("ip"),
            texto:
              "Tentou quitar para uma data anterior da conta receber N°" +
              this.conta.id
          };
          await this.$logger(logData);
          return 1;
        }
      } else {
        if (mes_hoje + 1 < mes_data && !(mes_hoje == 1 && mes_data == 12)) {
          this.movimento.data = hoje.toISOString().split("T")[0];
          this.$vs.notify({
            title: "Não é possível movimentar o mês anterior. ERR COD 02",
            text: "Ação Negada",
            color: "danger"
          });
          let logData = {
            id_colaborador: localStorage.getItem("id"),
            funcao: "burl",
            setor: "financeiro",
            ip: window.localStorage.getItem("ip"),
            texto:
              "Tentou quitar para uma data anterior da conta receber N°" +
              this.conta.id
          };
          await this.$logger(logData);
          return 1;
        }
      }
    },
    async getFormasPagamento() {
      try {
        this.formas_pagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  async mounted() {
    await this.openModal();
  },
  components: { ...components }
};
</script>
