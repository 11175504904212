<template>
  <div>
    <vs-button
      icon="search"
      type="line"
      size="small"
      @click="openModal()"
      color="#3da4fd"
    >
      <b style="font-size: 16px">Filtrar</b>
    </vs-button>
    <vs-popup v-if="open" title="Filtrar" :active.sync="modalSync">
      <div class="w-full">
        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              style="width: 460px"
              class="mx-1 inputx w-full"
              type="text"
              placeholder="Ex: Murad Murched"
              size="small"
              label="Cliente"
              v-model="filtro.cliente"
            ></vs-input>
            <vs-input
              color="dark"
              class="mx-1"
              type="text"
              placeholder="00000"
              size="small"
              label="NF"
              v-model="filtro.nf"
            ></vs-input>
          </vs-col>
        </vs-row>
        <div id="filtroSelects" class="vs-con-loading__container">
          <vs-row
            vs-type="flex"
            class="mb-2"
            vs-justify="space-between"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="space-between"
              vs-align="flex-end"
              vs-w="12"
            >
              <el-select
                filterable
                clearable
                placeholder="Tipo de Pessoa"
                size="small"
                v-model="filtro.tipo_pessoa"
                class="mx-1 mt-2"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="(tipo, index) in tipo_pessoa"
                  :value="tipo.value"
                  :label="tipo.nome"
                  :key="index"
                ></el-option>
              </el-select>
              <el-select
                filterable
                clearable
                size="small"
                placeholder="Forma de Pagamento"
                v-model="filtro.id_forma_pagamento"
                class="mx-1 mt-2"
                :popper-append-to-body="false"
              >
                <el-option
                  v-for="formaPagamento in formasPagamento"
                  :value="formaPagamento.id"
                  :label="formaPagamento.nome"
                  :key="formaPagamento.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                clearable
                size="small"
                placeholder="Empresa"
                v-model="filtro.id_empresa"
                :popper-append-to-body="false"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="empresa in empresas"
                  :value="empresa.id"
                  :label="empresa.razao_social"
                  :key="empresa.id"
                ></el-option>
              </el-select>
            </vs-col>
          </vs-row>
          <vs-row
            vs-type="flex"
            class="mb-2"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-end"
              vs-w="12"
            >
              <el-select
                filterable
                :popper-append-to-body="false"
                clearable
                size="small"
                placeholder="Portadores"
                v-model="filtro.id_portador"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="portador in portadores"
                  :value="portador.id"
                  :label="portador.nome"
                  :key="portador.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                clearable
                remote
                :remote-method="filtroPlanoConta"
                loading-text="Buscando..."
                size="small"
                :loading="loading"
                :popper-append-to-body="false"
                placeholder="Plano Conta"
                v-model="filtro.id_plano_conta"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="data in plano_conta"
                  :value="data.id"
                  :label="data.nome"
                  :key="data.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                size="small"
                clearable
                name="status"
                placeholder="Status"
                :popper-append-to-body="false"
                v-model="filtro.id_status"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="data in status"
                  :value="data.id"
                  :label="data.nome"
                  :key="data.id"
                ></el-option>
              </el-select>
              <el-select
                filterable
                size="small"
                clearable
                name="vendedor"
                placeholder="vendedor"
                :popper-append-to-body="false"
                v-model="filtro.id_vendedor"
                class="mx-1 mt-2"
              >
                <el-option
                  v-for="data in vendedores"
                  :value="data.id"
                  :label="data.nome"
                  :key="data.id"
                ></el-option>
              </el-select>
            </vs-col>
          </vs-row>
        </div>
        <vs-row
          vs-type="flex"
          class="mt-2"
          vs-justify="flex-end"
          vs-align="flex-end"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-checkbox color="success" v-model="filtro.negativado">
              Negativado
            </vs-checkbox>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1"
              style="width: 300px"
              size="small"
              v-currency="$currencyFormatterOptions"
              type="text"
              placeholder="R$ 0,00"
              label="Valor De"
              v-model="filtro.valorDe"
            ></vs-input>
            <vs-input
              color="dark"
              style="width: 300px"
              class="mx-1"
              v-currency="$currencyFormatterOptions"
              type="text"
              size="small"
              placeholder="R$ 0,00"
              label="Valor Até"
              v-model="filtro.valorAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-2"
              size="small"
              type="date"
              style="width: 300px"
              label="Quitacao De"
              v-model="filtro.pagamentoDe"
            ></vs-input>
            <vs-input
              color="dark"
              class="mx-2"
              type="date"
              style="width: 300px"
              label="Quitacao Até"
              size="small"
              v-model="filtro.pagamentoAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-2"
              size="small"
              type="date"
              style="width: 300px"
              label="Vencimento De"
              v-model="filtro.dataDe"
            ></vs-input>
            <vs-input
              color="dark"
              class="mx-2"
              type="date"
              style="width: 300px"
              label="Vencimento Até"
              size="small"
              v-model="filtro.dataAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-2"
              size="small"
              type="date"
              style="width: 300px"
              label="Emissão De"
              v-model="filtro.emissaoDe"
            ></vs-input>
            <vs-input
              color="dark"
              class="mx-2"
              style="width: 300px"
              type="date"
              label="Emissão Até"
              size="small"
              v-model="filtro.emissaoAte"
            ></vs-input>
          </vs-col>
        </vs-row>
        <vs-row
          vs-type="flex"
          class="mb-2"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="12"
          >
            <vs-input
              color="dark"
              class="mx-1 w-fulll"
              style="width: 600px"
              type="text"
              label="Observação"
              v-model="filtro.obs"
            ></vs-input>
          </vs-col>
        </vs-row>

        <vs-row vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="6"
          >
            <vs-button
              @click="Filtrar()"
              class="w-full mt-3 mx-2"
              color="success"
              type="relief"
              >Filtrar</vs-button
            >
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="flex-end"
            vs-w="6"
          >
            <vs-button
              @click="(limpar = true), Filtrar()"
              class="w-full mt-3 mx-2"
              color="danger"
              type="relief"
              >Filtrar e Limpar</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  data() {
    return {
      tipo_pessoa: [
        { value: 1, nome: "PESSOA FÍSICA" },
        { value: 2, nome: "PESSOA JURÍDICA" }
      ],
      empresas: {},
      portadores: {},
      vendedores: {},
      plano_conta: {},
      formasPagamento: {},
      status: {},
      modalSync: false,
      filtro: {},
      limpar: false,
      open: false,
      loading: false
    };
  },
  methods: {
    async replaceDots(value) {
      if (value && value.search(",") !== -1) {
        value = value.replace(/\./g, "");
        value = value.replace(/,/g, ".");
      }
      return value;
    },

    async Filtrar() {
      this.filtro.valorDe = await this.replaceDots(this.filtro.valorDe);
      this.filtro.valorAte = await this.replaceDots(this.filtro.valorAte);

      await this.$emit("filtro", this.filtro);
      this.modalSync = await false;
      if (this.limpar) {
        this.filtro = {};
        this.limpar = false;
      }
    },
    async getEmpresas() {
      try {
        this.empresas = await this.$http.get(`empresa`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getFormasPagamento() {
      try {
        this.formasPagamento = await this.$http.get(`formasPagamento`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getPortadores() {
      try {
        this.portadores = await this.$http.get(`portador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getVendedores() {
      try {
        this.vendedores = await this.$http.get(`colaborador`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async getContaStatus() {
      try {
        this.status = await this.$http.get(`conta_status`);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async openModal() {
      this.open = await true;
      this.modalSync = await true;
      await this.$vs.loading({ container: "#filtroSelects", scale: 0.6 });
      try {
        this.filtro.dataHoje = this.$formartData.dataFiltro();
        await this.getPortadores();
        await this.getEmpresas();
        await this.getVendedores();
        await this.getFormasPagamento();
        await this.getContaStatus();
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#filtroSelects > .con-vs-loading");
      }
    },
    async getPlanoContaByQuery(query) {
      try {
        this.plano_conta = await this.$http.post(`getPlanoContaByQuery`, {
          query: query
        });
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    async filtroPlanoConta(query) {
      if (query.length > 2) {
        this.loading = true;
        try {
          await this.getPlanoContaByQuery(query);
        } catch (err) {
          const error = this.$httpErrors(err);
          this.$vs.notify(error);
        } finally {
          this.loading = false;
        }
      }
    }
  },
  async mounted() {},
  components: {
    ...components
  }
};
</script>
