<template>
  <div class="w-full">
    <vs-popup
      v-if="open"
      title="Pagamentos Anteriores"
      :active.sync="modalPagamentoParcial"
    >
      <div>
        <vs-table stripe max-items="9" pagination :data="data">
          <template slot="thead">
            <vs-th style="width: 20%" id="center">Quitação</vs-th>
            <vs-th style="width: 20%" id="center">Valor</vs-th>
            <vs-th style="width: 20%" id="center">Portador</vs-th>
            <vs-th style="width: 80%">Obs</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr
              :style="
                contaParcial.lixeira == 0 || contaParcial.lixeira == null
                  ? 'color: green; background-color: #e8fce8 !important'
                  : 'color:red ;background-color: #fce3e3 !important'
              "
              :key="contaParcial.id"
              v-for="contaParcial in data"
            >
              <vs-td class="text-center" style="font-size: 12px">
                <b>{{ contaParcial.data }}</b>
              </vs-td>
              <vs-td class="text-center" style="font-size: 12px">
                <b>{{
                  $currency(
                    parseFloat(contaParcial.valor) +
                      parseFloat(contaParcial.juros || 0)
                  )
                }}</b>
              </vs-td>
              <vs-td class="text-center" style="font-size: 12px">
                <b>{{ contaParcial.portador }}</b>
              </vs-td>
              <vs-td style="text-align: justify; font-size: 12px">
                <b>{{ contaParcial.obs }}</b>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import components from "@/components/default/exports.js"

export default {
  props: {
    conta: {},
  },
  data () {
    return {
      open: false,
      modalPagamentoParcial: false,
      data: [],
    }
  },
  methods: {
    async openModal () {
      this.open = await true
      this.modalPagamentoParcial = await true
      await this.getContasParciais()
    },

    async getContasParciais () {
      try {
        this.data = await this.$http.post(`getMovimentoFinanceirosByConta`, {
          id_conta: this.conta.id,
        })
      } catch (err) {
        const error = this.$httpErrors(err)
        this.$vs.notify(error)
      }
    },
  },
  async mounted () {
    await this.openModal()
  },
  components: { ...components },
};
</script>
